import React, { useState } from 'react';
import {
  string, func, arrayOf, shape, number, bool
} from 'prop-types';
// eslint-disable-next-line import/no-cycle
import ListViews from './ListViews';
import ViewDetails from './ViewDetails';
import ViewForm from './ViewForm';
import * as API from '../api/ViewsApi';
import { formatViews, VIEWS_SECTIONS } from '../utils/utils';

export default function Views({
  contentType,
  handleVisualize,
  getViews,
  views,
  setViews,
  sectionToShow,
  setSectionToShow,
  selectedView,
  setSelectedView,
  initialColumns,
  setViewToVisualize
}) {
  const [viewToUpdateOrCopy, setViewToUpdateOrCopy] = useState();

  const handleSearchView = async (e) => {
    e.preventDefault();
    const result = await API.getViewsByContentType(contentType, e.target['0'].value);
    const formattedResult = formatViews(result);
    setViews(formattedResult);
  };

  const handleCreateView = () => {
    setSectionToShow(VIEWS_SECTIONS.CREATE);
  };

  const handleUpdateView = (view) => {
    setViewToUpdateOrCopy(view);
    setSectionToShow(VIEWS_SECTIONS.UPDATE);
  };

  const handleDetailsView = (view) => {
    setSelectedView(view);
    setSectionToShow(VIEWS_SECTIONS.DETAILS);
  };

  const handleCopyView = (view) => {
    setViewToUpdateOrCopy(view);
    setSectionToShow(VIEWS_SECTIONS.COPY);
  };

  const handleDeleteView = async (viewToDelete) => {
    await API.deleteView(viewToDelete.id);

    const newViews = await getViews();
    const newSelectedView = newViews.find((view) => view.default);
    setViews(newViews);

    if (selectedView && selectedView.id === viewToDelete.id && newSelectedView) {
      setSectionToShow(VIEWS_SECTIONS.DETAILS);
      setSelectedView(newSelectedView);
      setViewToVisualize(newSelectedView);
    }
  };

  const handleUpdateList = async (view) => {
    const updatedViews = await getViews();
    setViews(updatedViews);
    setSelectedView(updatedViews.find((updatedView) => updatedView.id === view.id));
    setSectionToShow(VIEWS_SECTIONS.DETAILS);
    handleVisualize(view);
  };

  return (
    <div className="collapse py-3 mb-3 views-collpase-section" id="views">
      {views && (
        <div className="d-flex flex-column flex-xl-row">
          <ListViews
            handleCreateView={handleCreateView}
            handleUpdateView={handleUpdateView}
            handleDetailsView={handleDetailsView}
            handleCopyView={handleCopyView}
            handleDeleteView={handleDeleteView}
            views={views}
            selectedView={selectedView?.id}
            handleSearchViews={handleSearchView}
            handleVisualize={() => handleVisualize(selectedView)}
          />
          <div className="border-left" />
          <div className="w-100 w-xl-50 pl-0 pl-xl-3 mt-5 mt-xl-0">
            {sectionToShow === VIEWS_SECTIONS.DETAILS && (
              <ViewDetails view={selectedView} />
            )}
            {sectionToShow === VIEWS_SECTIONS.CREATE && (
              <ViewForm
                action="CREATE"
                title={gettext('create view')}
                buttonText={gettext('save')}
                initialColumns={initialColumns}
                handleUpdateList={handleUpdateList}
              />
            )}
            {sectionToShow === VIEWS_SECTIONS.UPDATE && (
              <ViewForm
                action="UPDATE"
                title={gettext('update view')}
                buttonText={gettext('update')}
                view={viewToUpdateOrCopy}
                initialColumns={initialColumns}
                handleUpdateList={handleUpdateList}
              />
            )}
            {sectionToShow === VIEWS_SECTIONS.COPY && (
              <ViewForm
                action="CREATE"
                title={gettext('copy view')}
                buttonText={gettext('copy')}
                view={viewToUpdateOrCopy}
                initialColumns={initialColumns}
                handleUpdateList={handleUpdateList}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Views.propTypes = {
  contentType: string.isRequired,
  handleVisualize: func.isRequired,
  getViews: func.isRequired,
  setViews: func.isRequired,
  sectionToShow: string.isRequired,
  setSectionToShow: func.isRequired,
  setViewToVisualize: func.isRequired,
  setSelectedView: func.isRequired,
  selectedView: shape({
    columns: arrayOf(shape({
      id: number.isRequired,
      shortName: string.isRequired,
      observations: string.isRequired
    })),
    default: bool.isRequired,
    description: string.isRequired,
    displayName: string.isRequired,
    id: number.isRequired,
    user: number
  }),
  views: arrayOf(shape({
    columns: arrayOf(shape({
      id: number.isRequired,
      shortName: string.isRequired
    })),
    default: bool.isRequired,
    description: string.isRequired,
    displayName: string.isRequired,
    id: number.isRequired,
    user: number
  })),
  initialColumns: arrayOf(shape({
    id: number,
    shortName: string.isRequired,
    longName: string.isRequired,
    englishName: string
  })).isRequired
};

Views.defaultProps = {
  selectedView: undefined,
  views: undefined
};
