/* eslint-disable camelcase */
import {
  arrayOf, bool, number, oneOfType, shape, string, func
} from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { mapApiLanguage } from '../utils/mapApiLanguage';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';

function Prefilter({
  isMultiple, choices, name, label, handleSelect, tooltip
}) {
  const formattedOptions = choices?.map(([value, text]) => (
    {
      value: String(value),
      label: text,
      isMultiple
    })
  );

  return (
    <div className="col-sm-4 col-12">
      <div>
        <label className="font-weight-bold mr-1" htmlFor={name}>{label}</label>
        <PopoverIcon icon="fa-info-circle" popoverText={tooltip} popoverTitle={gettext(`filter ${label}`)} />
      </div>
      <Select
        isClearable
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
        options={formattedOptions}
        isMulti={isMultiple}
        onChange={(selected) => {
          if (!isMultiple) {
            handleSelect({ name, value: selected?.value });
            return;
          }

          const selectedValues = selected?.map((item) => item.value);
          handleSelect({ name, value: selectedValues });
        }}
      />
    </div>
  );
}

export default function PrefilterSelectors({
  prefiltersOptions,
  language,
  setPrefilters
}) {
  if (!prefiltersOptions?.length) {
    return null;
  }

  const selectNewPrefilter = (
    previousState,
    prefilterSelection
  ) => [...previousState, prefilterSelection];

  const removePrefilter = (previousState, prefilterSelection) => previousState.filter(
    (prefilter) => prefilter.name !== prefilterSelection.name
  );

  const updatePrefilter = (previousState, prefilterSelection) => previousState.map((prefilter) => {
    if (prefilter.name === prefilterSelection.name) {
      return prefilterSelection;
    }
    return prefilter;
  });

  const handleSelect = (newSelect) => {
    setPrefilters((prev) => {
      const foundPrefilter = prev?.find((prefilter) => prefilter.name === newSelect.name);

      if (!foundPrefilter) {
        return selectNewPrefilter(prev, newSelect);
      }

      if (!newSelect.value?.length) {
        return removePrefilter(prev, newSelect);
      }

      return updatePrefilter(prev, newSelect);
    });
  };

  return (
    <div className="row mb-4">
      { prefiltersOptions.map(({
        name, choices, label: labelCode, allows_multiple: isMultiple, tooltip
      }) => {
        const translatedLabel = labelCode?.[mapApiLanguage(language)];
        const prefilterLabel = (translatedLabel === null ? 'Language not available' : translatedLabel) ?? labelCode;

        return (
          <Prefilter
            tooltip={tooltip}
            key={name}
            choices={choices}
            label={prefilterLabel}
            name={name}
            isMultiple={isMultiple}
            handleSelect={handleSelect}
          />
        );
      })}
    </div>
  );
}

PrefilterSelectors.propTypes = {
  setPrefilters: func.isRequired,
  language: string.isRequired,
  prefiltersOptions: arrayOf(shape({
    tooltip: string,
    name: string,
    allows_multiple: bool,
    label: oneOfType([shape({
      shortName: string,
      longName: string,
      englishName: string
    }), string]).isRequired,
    choices: arrayOf(arrayOf(oneOfType([string, number])))
  }))
};

PrefilterSelectors.defaultProps = {
  prefiltersOptions: []
};

Prefilter.propTypes = {
  tooltip: string.isRequired,
  isMultiple: bool.isRequired,
  choices: arrayOf(arrayOf(oneOfType([string, number]))).isRequired,
  name: string.isRequired,
  label: string.isRequired,
  handleSelect: func.isRequired
};
