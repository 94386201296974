import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import {
  string, number, bool, oneOf
} from 'prop-types';
import { userContentTypeContext } from './context/context';
import Views from './components/Views';
import GenericTable from './components/DataTable';
import * as API from './api/ViewsApi';
import {
  formatViews, VIEWS_SECTIONS, modelByContentType, formatSorting, formatPrefiltersArray
} from './utils/utils';
import PrefilterSelectors from './components/PrefilterSelectors';

export default function TableView({
  contentType, user, language, isAdmin, prefilter, initialOrder = 'desc'
}) {
  const [viewsActive, setViewsActive] = useState(false);
  const [views, setViews] = useState();
  const [sectionToShow, setSectionToShow] = useState('');
  const [selectedView, setSelectedView] = useState();
  const [viewToVisualize, setViewToVisualize] = useState();
  const [initialColumns, setInitialColumns] = useState([]);
  const [tableData, setTableData] = useState({});
  const [recordsPerPages, setRecorsPerPages] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [timeoutAndAbortSearch, setTimeoutAndAbortSearch] = useState();
  const [sorting, setSorting] = useState();
  const [searchInputText, setSearchInputText] = useState('');
  const searchInputRef = useRef();
  const [dataTableEditorResources, setDataTableEditorResources] = useState({});
  const [prefilterOptions, setPrefilterOptions] = useState([]);
  const [prefiltersList, setPrefiltersList] = useState([]);

  // Sets up the initial ordering when tables load based on the first column
  // Notice order is given by TableView props.
  // If no initial order is provided, uses 'desc' as default
  useEffect(() => {
    if (!sorting && selectedView) {
      const { shortName: firstTableColumn } = selectedView?.columns[0];
      setSorting({
        order: initialOrder,
        property: firstTableColumn
      });
    }
  }, [selectedView, sorting, initialOrder]);

  useEffect(() => {
    const getResources = async () => {
      const response = await API.getDataTableEditorResources();
      if (response) {
        setDataTableEditorResources(response);
      }
    };

    getResources();
  }, []);

  const getViews = useCallback(async () => {
    const result = await API.getViewsByContentType(contentType);
    if (result) {
      const formattedResult = formatViews(result);
      return formattedResult;
    }
    return [];
  }, [contentType]);

  const getPrefilters = async (preFilters) => {
    const response = await API.getPrefilterOptions(contentType, preFilters);
    setPrefilterOptions(response?.available_prefilters);
  };

  useEffect(() => {
    const getColumnsByContentType = async () => {
      const result = await API.getColumnsByContentType(contentType);
      if (result) {
        const formattedResult = result.nomenclatorEntries.map((item) => ({
          id: item.id,
          shortName: item.shortName,
          observations: item.observations,
          longName: item.longName,
          englishName: item.englishName,
          requiredByDataview: item.requiredByDataview
        }));

        setInitialColumns(formattedResult);
      }
    };

    getColumnsByContentType();
  }, [contentType]);

  useEffect(() => {
    const getViewsByContentType = async () => {
      const newViews = await getViews();
      const newSelectedView = newViews.find((view) => view.default);
      if (newSelectedView) {
        setSectionToShow(VIEWS_SECTIONS.DETAILS);
        setSelectedView(newSelectedView);
        setViewToVisualize(newSelectedView);
      }
      setViews(newViews);
    };

    getViewsByContentType();
  }, [getViews]);

  useEffect(() => {
    const formattedPrefilters = formatPrefiltersArray(prefiltersList);
    getPrefilters(formattedPrefilters);

    const getDataTableByContentType = async () => {
      if (viewToVisualize) {
        setIsLoadingTable(true);
        const result = await API.getDataTableByContentType(
          undefined,
          contentType,
          recordsPerPages,
          currentPage,
          viewToVisualize.displayName,
          searchInputRef.current.value || undefined,
          formattedPrefilters,
          sorting,
        );

        if (result) {
          setTableData({ ...result, viewToVisualize });
        }
        setIsLoadingTable(false);
      }
    };
    getDataTableByContentType();
  }, [
    contentType,
    recordsPerPages,
    currentPage,
    viewToVisualize,
    prefilter,
    sorting,
    prefiltersList
  ]);

  useEffect(() => {
    if (selectedView?.id === viewToVisualize?.id) {
      setViewToVisualize(selectedView);
    }
  }, [selectedView, viewToVisualize]);

  const handleVisualize = (view) => {
    setViewToVisualize(view);
    setCurrentPage(1);
    setSearchInputText('');
    setSorting(undefined);
  };

  const handleChangeSearch = (e) => {
    const searchText = e.target.value;
    setSearchInputText(e.target.value);
    setCurrentPage(1);

    if (timeoutAndAbortSearch) {
      clearTimeout(setTimeoutAndAbortSearch.timeout);
      timeoutAndAbortSearch.aborting.abort();
    }

    // eslint-disable-next-line no-undef
    const controller = new AbortController();

    setTimeoutAndAbortSearch({
      aborting: controller,
      timeout: setTimeout(async () => {
        const formattedPrefilters = formatPrefiltersArray(prefiltersList);
        setIsLoadingTable(true);
        const result = await API.getDataTableByContentType(
          controller.signal,
          contentType,
          recordsPerPages,
          currentPage,
          viewToVisualize.displayName,
          searchText,
          formattedPrefilters,
          sorting
        );
        if (result) {
          setTableData({ ...result, viewToVisualize });
          setCurrentPage(1);
        }
        setIsLoadingTable(false);
      }, 200)
    });
  };

  const handleChangeRegistersNumber = (e) => {
    setRecorsPerPages(Number(e.target.value));
    setCurrentPage(1);
  };

  const hasExpandableRows = useMemo(() => tableData?.results?.some(
    // eslint-disable-next-line no-underscore-dangle
    (rowData) => rowData?._hidden_table),
  [tableData]);

  return (
    <userContentTypeContext.Provider value={{
      user,
      contentType,
      isAdmin,
      language,
      dataTableEditorResources
    }}
    >
      <div className="card card-data-table">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <h3 className="mb-0 font-weight-bold">{gettext(modelByContentType[contentType].title)}</h3>
              <span className="ml-3">{viewToVisualize?.displayName || ''}</span>
              <button
                type="button"
                className={`ml-3 btn ${viewsActive ? 'btn-primary active' : 'btn-outline-secondary'}`}
                data-toggle="collapse"
                data-target="#views"
                aria-expanded="false"
                aria-controls="views"
                onClick={() => setViewsActive(!viewsActive)}
              >
                {viewsActive ? gettext('close views') : gettext('views')}
              </button>
            </div>
            <div className="d-flex">
              <label className="ml-3 mb-0 d-flex align-items-center" htmlFor="search">
                {gettext('search')}
                :
                <input
                  value={searchInputText}
                  ref={searchInputRef}
                  type="text"
                  name="search"
                  id="search"
                  className="ml-2 form-control"
                  onChange={handleChangeSearch}
                />
              </label>
            </div>
          </div>

          <PrefilterSelectors
            setPrefilters={setPrefiltersList}
            prefiltersOptions={prefilterOptions}
            language={language}
          />

          <Views
            initialColumns={initialColumns}
            handleVisualize={handleVisualize}
            user={user}
            contentType={contentType}
            getViews={getViews}
            views={views}
            setViews={setViews}
            sectionToShow={sectionToShow}
            setSectionToShow={setSectionToShow}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            setViewToVisualize={setViewToVisualize}
          />
          <label className="mb-3 d-flex align-items-center" htmlFor="number-records">
            {`${gettext('show')}`}
            <div className="ml-1 mr-1">
              <select value={recordsPerPages} onChange={handleChangeRegistersNumber} className="js-not-select2 form-control" name="number-records" id="number-records">
                <option value={1000}>TODOS</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
            {`${gettext('records')}`}
          </label>
          <GenericTable
            language={language}
            selectedView={selectedView}
            tableData={tableData}
            recordsPerPages={recordsPerPages}
            currentPage={currentPage}
            handleChangeCurrentPage={(newCurrentPage) => setCurrentPage(newCurrentPage)}
            isLoadingTable={isLoadingTable}
            handleChangeSorting={(newSorting) => setSorting(newSorting)}
            sorting={sorting}
            expandableRows={hasExpandableRows}
          />
          <div>
            <a
              className="btn btn-primary"
              rel="noreferrer"
              target="_blank"
              href={`/api/v1/${modelByContentType[contentType].getDataEndPoint}/?format=xlsx${viewToVisualize ? `&view_name=${viewToVisualize.displayName}` : ''}${searchInputText ? `&search=${encodeURIComponent(searchInputText)}` : ''}&${formatSorting(sorting)}`}
            >
              {`${gettext('download excel file')}`}
            </a>
          </div>
        </div>
      </div>
    </userContentTypeContext.Provider>
  );
}

TableView.propTypes = {
  contentType: string.isRequired,
  user: number.isRequired,
  language: string.isRequired,
  isAdmin: bool.isRequired,
  prefilter: string,
  initialOrder: oneOf(['asc', 'desc'])
};

TableView.defaultProps = {
  prefilter: '',
  initialOrder: 'desc'
};
