import React from 'react';
import {
  func,
  arrayOf,
  number,
  string,
  bool,
  shape
} from 'prop-types';
// eslint-disable-next-line import/no-cycle
import ViewRow from './ViewRow';

export default function ListViews({
  handleVisualize,
  handleCreateView,
  handleUpdateView,
  handleDetailsView,
  handleCopyView,
  handleSearchViews,
  handleDeleteView,
  views,
  selectedView
}) {
  return (
    <div className="w-100 w-xl-50 pr-0 pr-xl-5">
      <div className="mb-4 mr-3 d-flex align-items-center">
        <p className="mb-0">
          {gettext('search')}
        </p>
        <form className="d-flex flex-grow-1" onSubmit={handleSearchViews}>
          <input className="form-control ml-3" />
          <input
            className="text-nowrap btn btn-cuanta ml-3"
            type="submit"
            value={gettext('search')}
          />
        </form>
        <button
          onClick={handleCreateView}
          className="text-nowrap btn btn-outline-secondary ml-3"
          type="button"
        >
          {`${gettext('new view')} +`}
        </button>
      </div>
      <strong className="d-block">{gettext('views list')}</strong>
      <div className="list-views pr-3">
        {views.map((view) => (
          <ViewRow
            key={view.id}
            handleUpdateView={() => handleUpdateView(view)}
            handleDetailsView={() => handleDetailsView(view)}
            handleCopyView={() => handleCopyView(view)}
            handleDeleteView={() => handleDeleteView(view)}
            id={view.id}
            isDefault={view.default}
            checked={selectedView === view.id}
            viewName={view.displayName}
            viewUser={view.user}
            viewDescription={view.description}
          />
        ))}
      </div>
      <button
        className="btn btn-cuanta mt-3"
        type="button"
        onClick={handleVisualize}
      >
        {gettext('visualize')}
      </button>
    </div>
  );
}

ListViews.propTypes = {
  handleVisualize: func.isRequired,
  handleCreateView: func.isRequired,
  handleUpdateView: func.isRequired,
  handleDetailsView: func.isRequired,
  handleCopyView: func.isRequired,
  handleSearchViews: func.isRequired,
  handleDeleteView: func.isRequired,
  selectedView: number,
  views: arrayOf(shape({
    columns: arrayOf(shape({
      id: number.isRequired,
      shortName: string.isRequired
    })),
    default: bool.isRequired,
    description: string.isRequired,
    displayName: string.isRequired,
    id: number.isRequired,
    user: number
  })).isRequired
};

ListViews.defaultProps = {
  selectedView: 0
};
