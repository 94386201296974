/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  shape,
  string
} from 'prop-types';
import TransferList from './TransferList';
import * as API from '../api/ViewsApi';
import { userContentTypeContext } from '../context/context';
import { LANGUAGES_CHOICES, nomenclatorLabel } from '../utils/utils';

const ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

export default function ViewForm({
  title,
  view,
  initialColumns,
  handleUpdateList,
  buttonText,
  action
}) {
  const [name, setName] = useState(view.name || '');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [description, setDescription] = useState(view.description || '');
  const [selectedColumns, setSelectedColumns] = useState(view.columns);
  const [isErrorTransferList, setIsErrorTransferList] = useState(false);
  const [fetchError, setFetchError] = useState({ error: false, message: '' });
  const [actionState, setAction] = useState(action || '');
  const {
    contentType,
    isAdmin,
    language,
    dataTableEditorResources
  } = useContext(userContentTypeContext);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  useEffect(() => {
    setName(view.name);
    setDescription(view.description);

    const requiredColumns = initialColumns.filter((column) => column.requiredByDataview);
    const columnsJoined = [...requiredColumns, ...view.columns];
    const columnsFiltered = columnsJoined
      .filter((column, index) => {
        const columnFound = columnsJoined.find((col) => col.shortName === column.shortName);
        return columnsJoined.indexOf(columnFound) === index;
      });
    setSelectedColumns(columnsFiltered);
  }, [view, initialColumns]);

  useEffect(() => {
    setAction(action);
  }, [action]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name !== '' && description !== '' && selectedColumns.length) {
      const newView = {
        name,
        description,
        columns: selectedColumns.map((col, index) => ({
          nomenclator_entry: col.shortName,
          index
        }))
      };
      let result;
      setDisableSubmit(true);
      if (actionState === ACTIONS.CREATE) {
        result = await API.createView(contentType, newView);
      } else if (action === ACTIONS.UPDATE) {
        result = await API.updateView(view.id, newView, (isAdmin && view.default));
      }
      if (result.error) {
        setFetchError({
          error: true,
          message: result.error
        });
      } else {
        result = {
          ...result,
          columns: result.columns.map((columnToFormat) => (
            {
              ...columnToFormat.nomenclatorEntry
            }
          ))
        };
        handleUpdateList(result);
      }
      setDisableSubmit(false);
    } else if (!selectedColumns.length) {
      setIsErrorTransferList(true);
    }
  };

  const handleChangeSelectedColumns = (columns) => {
    setIsErrorTransferList(false);
    setSelectedColumns(columns);
  };

  const handleSelectLanguage = (e) => {
    setSelectedLanguage(e.target.value);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <strong className="d-block">{title}</strong>
        <button type="submit" className={`btn btn-link ${disableSubmit ? 'disabled' : ''}`}>
          <i className="mr-1 fas fa-save" />
          <u>{buttonText}</u>
        </button>
      </div>
      {fetchError.error && (
        <span className="mb-3 d-block text-danger">{fetchError.message}</span>
      )}
      <div className="form-group d-flex">
        { /* View name */ }
        <label className="w-50 mb-0" htmlFor="name">{`${nomenclatorLabel('385_slctor_view_name', dataTableEditorResources, selectedLanguage)}:`}</label>
        <input
          required
          className="form-control"
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group d-flex">
        { /* Description */ }
        <label className="w-50 mb-0" htmlFor="description">{`${nomenclatorLabel('386_slctor_view_des', dataTableEditorResources, selectedLanguage)}:`}</label>
        <textarea
          required
          className="form-control"
          name="description"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="form-group d-flex mb-4">
        { /* Language */ }
        <label className="w-50 mb-0" htmlFor="language">{`${nomenclatorLabel('391_slctor_lang', dataTableEditorResources, selectedLanguage)}:`}</label>
        <div className="w-100">
          <select
            value={selectedLanguage}
            onChange={handleSelectLanguage}
            className="form-control"
            name="language"
            id="language"
          >
            <option
              value={LANGUAGES_CHOICES.SPANISH.value}
            >
              {LANGUAGES_CHOICES.SPANISH.label}
            </option>
            <option
              value={LANGUAGES_CHOICES.ENGLISH.value}
            >
              {LANGUAGES_CHOICES.ENGLISH.label}
            </option>
            <option
              value={LANGUAGES_CHOICES.NOMENCLATOR.value}
            >
              {LANGUAGES_CHOICES.NOMENCLATOR.label}
            </option>
          </select>
        </div>
      </div>
      <strong className="d-block mb-3">{gettext('entries select')}</strong>
      <TransferList
        dataTableEditorResources={dataTableEditorResources}
        language={selectedLanguage}
        columnsTransfered={selectedColumns}
        columnsAvailable={initialColumns}
        handleChangeSelectedColumns={handleChangeSelectedColumns}
        isError={isErrorTransferList}
      />
    </form>
  );
}

ViewForm.propTypes = {
  title: string.isRequired,
  handleUpdateList: func.isRequired,
  buttonText: string.isRequired,
  view: shape({
    columns: arrayOf(shape({
      shortName: string.isRequired,
      longName: string.isRequired,
      englishName: string
    })),
    default: bool.isRequired,
    description: string.isRequired,
    name: string.isRequired,
    id: number.isRequired,
    user: number
  }),
  initialColumns: arrayOf(shape({
    shortName: string.isRequired,
    longName: string.isRequired,
    englishName: string
  })).isRequired,
  action: oneOf([ACTIONS.CREATE, ACTIONS.UPDATE]).isRequired
};

ViewForm.defaultProps = {
  view: {
    columns: [],
    default: false,
    description: '',
    name: '',
    id: 0,
    user: 0
  }
};
