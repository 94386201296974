import React, { useEffect, useRef, useState } from 'react';
import { func } from 'prop-types';

export default function ConfirmDeletePopover({ handleDeleteView }) {
  const refButton = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const clearHistoryBtn = `
    <div class="d-flex justify-content-end">
      <button id="delete-button" type="button" class="btn btn-cuanta mr-2">${gettext('delete')}</button>
      <button id="cancel-button" type="button" class="btn btn-outline-secondary">${gettext('cancel')}</button>
    </div>
`;

  useEffect(() => {
    if (refButton.current) {
      $(refButton.current).popover({
        html: true,
        sanitize: false,
        content: clearHistoryBtn,
        trigger: 'focus',
        boundary: 'window'
      });

      $(refButton.current).on('hidden.bs.popover', () => {
        setPopoverOpen(false);
      });

      $(refButton.current).on('shown.bs.popover', () => {
        setPopoverOpen(true);
      });
    }
  }, [clearHistoryBtn, refButton]);

  const handleDelete = () => {
    handleDeleteView();
  };

  const handleClick = () => {
    if (popoverOpen) {
      $(refButton.current).popover('hide');
    } else {
      $(refButton.current).popover('show');
    }
    $('#delete-button').on('click', () => handleDelete());
  };

  return (
    <>
      <button
        onClick={handleClick}
        data-toggle="delete-popover"
        data-original-title={gettext('are you sure you want delete this view?')}
        data-html="true"
        type="button"
        className="bg-transparent border-0"
        ref={refButton}
      >
        <i className="fas fa-trash-alt" />
      </button>
    </>
  );
}

ConfirmDeletePopover.propTypes = {
  handleDeleteView: func.isRequired
};
