import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import NetworksTreeItem from './components/NetworksTreeItem';
import NetworkFilters from './components/NetworkFilters';
import NetworksApi from './api/NetworksApi';
import PopoverIcon from '../PopoverIcon/PopoverIconComponent';

const translations = {
  company: 'empresa',
  site: 'sitio',
  service: 'servicio',
  resource: 'recurso'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadNetworks':
      return {
        ...state,
        loading: false,
        cidrs: action.payload
      };
    case 'openSelector':
      return {
        ...state,
        networksSelector: {
          loading: true,
          isOpen: true,
          cidrs: []
        }
      };
    case 'closeSelector':
      return {
        ...state,
        loading: true,
        networksSelector: {
          isOpen: false
        }
      };
    case 'setFilters':
      return {
        ...state,
        loading: true,
        filters: action.payload
      };
    default: throw new Error(`Unknown action type ${action.type}`);
  }
};

export default function NetworksTree({
  companyId, siteId, serviceId, resourceId, companyNetworkTypes, filters, assignMode, showAssignNew
}) {
  // The mode is the context in which this component is used.
  // Are you viewing service networks? the site ones? all?
  let currentMode = 'all';
  if (siteId) {
    currentMode = 'site';
  } else if (companyId) {
    currentMode = 'company';
  } else if (serviceId) {
    currentMode = 'service';
  } else if (resourceId) {
    currentMode = 'resource';
  }

  const [state, dispatch] = useReducer(reducer, {
    cidrs: [],
    loading: true,
    mode: currentMode,
    filters,
    networksSelector: {
      loading: true,
      isOpen: false
    }
  });

  // eslint-disable-next-line no-console
  const filtersChangedHandler = useCallback((f) => {
    dispatch({ type: 'setFilters', payload: f });

    NetworksApi.getRelatedNetworks(
      f.currentNetworkGroup,
      companyId,
      siteId,
      serviceId,
      resourceId,
      assignMode,
      dispatch
    ).then((data) => dispatch({ type: 'loadNetworks', payload: data }));
  }, [companyId, siteId, serviceId, resourceId, assignMode, dispatch]);

  useEffect(() => {
    // Do not set the current network group if the filters are not set
    if (filters.currentNetworkGroup) {
      filtersChangedHandler(filters);
    }
  }, [filtersChangedHandler, filters, assignMode]);

  const showAssignControls = showAssignNew && state.networksSelector.isOpen;
  const statusTitle = `Estado ${translations[currentMode]}`;
  const showCompanyColumns = companyNetworkTypes.includes(state.filters.currentNetworkType);

  return (
    <div className="networks-tree-container">
      {/* Controls */}
      <div className="row mb-3">
        <div className="col-12 col-sm-5">
          {showAssignControls && (
            <h4 className="mt-4">
              Asignar red a&nbsp;
              {translations[state.mode]}
              :
            </h4>
          )}
        </div>
        <div className="col-12 col-sm-5">
          {!assignMode && (
            <NetworkFilters
              onChange={filtersChangedHandler}
              mode={`${currentMode}-${showAssignControls ? 'assign' : 'view'}`}
            />
          )}
        </div>
        <div className="col-12 col-sm-2">
          {showAssignNew && !state.networksSelector.isOpen && (
            <button
              className="btn btn-primary d-block ml-auto mb-3 mt-4"
              type="button"
              onClick={() => { dispatch({ type: 'openSelector' }); }}
            >
              <i className="fa fa-plus" />
              Asignar Red IP
            </button>
          )}
          {showAssignControls && (
            <button
              className="btn btn-primary d-block ml-auto mb-3 mt-4"
              type="button"
              onClick={() => {
                dispatch({ type: 'closeSelector' });
                NetworksApi.getRelatedNetworks(
                  state.filters.currentNetworkGroup,
                  companyId,
                  siteId,
                  serviceId,
                  resourceId,
                  assignMode
                )
                  .then((data) => dispatch({ type: 'loadNetworks', payload: data }));
              }}
            >
              <i className="fa fa-check" />
              Cerrar
            </button>
          )}
        </div>
      </div>
      {/* Loading indicator */}
      {state.loading && (
        <div>
          <i className="fas fa-circle-notch fa-spin" />
        </div>
      )}
      {/* Main table of the component.
          Columns for resources or assignment can be enabled with props
      */}
      {!state.networksSelector.isOpen && (
        <div className="table-responsive">
          <table className="table table-striped w-100">
            <thead>
              <tr>
                <th>
                  Sub red
                  <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                </th>
                {(state.mode !== 'company' || showCompanyColumns) && (
                  <th>
                    {statusTitle}
                    <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                  </th>
                )}
                {showCompanyColumns && (
                  <th>
                    Empresa
                    <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                  </th>
                )}
                <th>
                  Servicio
                  <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                </th>
                <th>
                  Recurso
                  <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                </th>
                <th>
                  Comentario
                  <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                </th>
                { assignMode && (
                  <>
                    <th>Asignar</th>
                    <PopoverIcon icon="fa-info-circle" popoverText="" popoverTitle={gettext('column observations')} />
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {
                state.cidrs.map((cidr) => (
                  <NetworksTreeItem
                    cidr={cidr}
                    depth={0}
                    key={cidr}
                    mode={state.mode}
                    companyId={companyId}
                    serviceId={serviceId}
                    resourceId={resourceId}
                    showCompanyColumns={showCompanyColumns}
                    assignable={assignMode}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
      )}
      {!state.networksSelector.isOpen && state.cidrs.length === 0 && !state.loading && (
        <b className="d-block text-center">No hay redes asociadas</b>
      )}
      {/* The 'assign mode' is just the same component, called
          again with different parameters: all networks instead of
          site ones and enabling assignment of networks. */}
      { showAssignControls && (
        <NetworksTree
          companyId={companyId}
          siteId={null}
          serviceId={serviceId}
          resourceId={resourceId}
          companyNetworkTypes={companyNetworkTypes}
          filters={state.filters}
          assignMode
          showAssignNew={false}
        />
      )}
    </div>
  );
}

NetworksTree.propTypes = {
  companyId: PropTypes.number,
  siteId: PropTypes.number,
  serviceId: PropTypes.number,
  resourceId: PropTypes.number,
  companyNetworkTypes: PropTypes.arrayOf(PropTypes.number),
  filters: PropTypes.shape({
    currentNetworkType: PropTypes.number,
    currentNetworkGroup: PropTypes.number
  }),
  assignMode: PropTypes.bool,
  showAssignNew: PropTypes.bool
};

NetworksTree.defaultProps = {
  companyId: null,
  siteId: null,
  serviceId: null,
  resourceId: null,
  companyNetworkTypes: [],
  filters: {
    currentNetworkType: 0,
    currentNetworkGroup: 0
  },
  assignMode: false,
  showAssignNew: false
};
