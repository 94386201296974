import React, { useContext } from 'react';
import {
  number, string, func, bool
} from 'prop-types';
import ButtonIcon from './ButtonIcon';
import { userContentTypeContext } from '../context/context';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';
import ConfirmDeletePopover from './ConfirmDeletePopover';

export default function ViewRow({
  viewName,
  viewUser,
  viewDescription,
  id,
  handleUpdateView,
  handleDetailsView,
  handleCopyView,
  handleDeleteView,
  checked,
  isDefault
}) {
  const { user, isAdmin } = useContext(userContentTypeContext);
  return (
    <div className="py-3 border-bottom d-flex justify-content-between align-items-center">
      <div className="form-check d-flex align-items-center">
        <input
          className="form-check-input"
          type="radio"
          name="views"
          checked={checked}
          onChange={handleDetailsView}
          id={id}
          required
        />
        <label className="form-check-label" htmlFor={id}>
          {viewName}
        </label>
      </div>
      <div className="text-nowrap">
        <PopoverIcon icon="fa-info-circle" popoverText={viewDescription} popoverTitle={gettext('view description')} />
        <ButtonIcon icon="fa-copy" handleClick={handleCopyView} />
        {user === viewUser && (
          <>
            <ButtonIcon icon="fa-edit" handleClick={handleUpdateView} />
            <ConfirmDeletePopover handleDeleteView={handleDeleteView} />
          </>
        )}
        {(isDefault && isAdmin) && (
          <>
            <ButtonIcon icon="fa-edit" handleClick={handleUpdateView} />
          </>
        )}
      </div>
    </div>
  );
}

ViewRow.propTypes = {
  viewName: string.isRequired,
  viewUser: number,
  id: number.isRequired,
  handleUpdateView: func.isRequired,
  handleDetailsView: func.isRequired,
  handleCopyView: func.isRequired,
  handleDeleteView: func.isRequired,
  checked: bool.isRequired,
  viewDescription: string.isRequired,
  isDefault: bool.isRequired
};

ViewRow.defaultProps = {
  viewUser: undefined
};

ViewRow.defaultProps = {
  viewUser: null
};
