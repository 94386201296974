export const VIEWS_SECTIONS = {
  DETAILS: 'DETAILS',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  COPY: 'COPY'
};

export const LANGUAGES_CHOICES = {
  SPANISH: {
    label: gettext('Spanish'),
    value: 'long_name'
  },
  ENGLISH: {
    label: gettext('English'),
    value: 'english_name'
  },
  NOMENCLATOR: {
    label: gettext('Nomenclator'),
    value: 'short_name'
  }
};

export const formatViews = (viewsToFormat) => {
  const formattedResult = viewsToFormat.map((view) => ({
    columns: view.columns.map((columnView) => ({
      id: columnView.id,
      shortName: columnView.nomenclatorEntry.shortName,
      observations: columnView.nomenclatorEntry.observations,
      longName: columnView.nomenclatorEntry.longName,
      englishName: columnView.nomenclatorEntry.englishName,
      requiredByDataview: columnView.nomenclatorEntry.requiredByDataview,
      isSortable: columnView.nomenclatorEntry.isSortable
    })),
    default: view.default,
    description: view.description,
    displayName: view.displayName,
    name: view.name,
    id: view.id,
    user: view.user
  }));
  return formattedResult;
};

export const modelByContentType = {
  companies_company: {
    getDataEndPoint: 'companies',
    title: gettext('Companies list')
  },
  places_site: {
    getDataEndPoint: 'sites',
    title: gettext('Sites list')
  },
  resources_physicalresource: {
    getDataEndPoint: 'resources/physical',
    title: gettext('Physical resources list')
  },
  resources_carrierresource: {
    getDataEndPoint: 'resources/carrier',
    title: gettext('Carrier resources list')
  },
  services_homologatedservice: {
    getDataEndPoint: 'homologatedservices',
    title: gettext('Homologated services list')
  },
  services_service: {
    getDataEndPoint: 'services',
    title: gettext('Services list')
  },
  resources_waybill: {
    getDataEndPoint: 'resources/waybill',
    title: gettext('Waybills list')
  },
  invoices_invoicedescription: {
    getDataEndPoint: 'invoices/invoicedescription',
    title: gettext('Invoice descriptions list')
  },
  resources_carrierresourcepurchaseorder: {
    getDataEndPoint: 'resources/carrierresourcepurchaseorder',
    title: gettext('Carrier resource purchase orders list')
  },
  resources_physicalresourcepurchaseorder: {
    getDataEndPoint: 'resources/physicalresourcepurchaseorder',
    title: gettext('Physical resource purchase orders list')
  },
  resources_carrierresourcequotation: {
    getDataEndPoint: 'resources/carrierresourcequotation',
    title: gettext('Carrier resource purchase orders list')
  },
  resources_physicalresourcequotation: {
    getDataEndPoint: 'resources/physicalresourcequotation',
    title: gettext('Physical resource purchase orders list')
  },
  services_serviceinstallation: {
    getDataEndPoint: 'services/serviceinstallation',
    title: gettext('Service installations list')
  },
  services_servicedeliverycertificate: {
    getDataEndPoint: 'services/servicedeliverycertificate',
    title: gettext('Service delivery certificates')
  },
  users_user: {
    getDataEndPoint: 'accounts',
    title: gettext('Users')
  }
};

export const formatPrefilter = (prefilter) => {
  if (prefilter) {
    let filterQuery = '';

    prefilter.forEach((filter) => {
      filterQuery = `${filterQuery}&pre_filter=${filter}`;
    });

    return filterQuery;
  }
  return '';
};

export const formatPrefiltersArray = (prefilterObjectList) => {
  const result = prefilterObjectList?.map((filter) => {
    if (!Array.isArray(filter?.value)) {
      // eslint-disable-next-line prefer-template
      const prefilterString = filter.name + ':' + filter.value;
      return prefilterString;
    }

    // eslint-disable-next-line prefer-template
    return filter.name + ':' + filter.value.join(',');
  });

  return result;
};

export const formatSorting = (sorting) => {
  if (sorting) {
    return `order_by=${sorting.property}:${sorting.order}`;
  }
  return '';
};

export const nomenclatorLabel = (shortName, resources, language) => {
  if (Object.hasOwn(resources, shortName)) {
    const resource = resources[shortName];
    const label = resource[language] || shortName;
    return label;
  }
  return shortName;
};

/**
 * Creates a query string from an array containing a [name, value] array.
 * @param values {Array<string, any>[]}
 * @return string
 */
export const makeQueryString = (values) => {
  const queryString = new window.URLSearchParams();
  values.forEach((entry) => {
    const [name, value] = entry;
    queryString.append(name, value);
  });
  return queryString.toString();
};
