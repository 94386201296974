function setupNestedRowsTable($tableUnfiltered) {
  // filter already initialized tables
  const $table = $tableUnfiltered.filter(
    (i, obj) => !($.fn.dataTable.isDataTable(obj))
  );

  // initialize datatable
  const table = $table.DataTable({
    fixedHeader: true,
    scrollY: 400,
    language: {
      decimal: ',',
      thousands: '.',
      sProcessing: 'procesando...',
      sLengthMenu: 'mostrar _MENU_ registros',
      sZeroRecords: 'no se encontraron resultados',
      sEmptyTable: 'ningún dato disponible en esta tabla',
      sInfo: 'mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
      sInfoEmpty: 'mostrando registros del 0 al 0 de un total de 0 registros',
      sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
      sSearch: 'buscar:',
      sInfoThousands: ',',
      sLoadingRecords: 'cargando...',
      oPaginate: {
        sFirst: 'primero',
        sLast: 'último',
        sNext: 'siguiente',
        sPrevious: 'anterior'
      },
      oAria: {
        sSortAscending: ': activar para ordenar la columna de manera ascendente',
        sSortDescending: ': activar para ordenar la columna de manera descendente'
      },
      buttons: {
        copy: 'copiar',
        colvis: 'visibilidad'
      }
    },
    lengthMenu: [[-1, 10, 25, 50], ['Todos', 10, 25, 50]],
    order: [[0, 'asc']]
  });

  if (!table) {
    return;
  }

  function toggleNestedTable() {
    const $tr = $(this).parents('tr');
    const $button = $(this);
    const row = table.row($tr);
    const rowId = $tr.data('row-id');
    const $nestedTable = $(`.nested-table[data-parent-id="${rowId}"]`);
    const $icon = $button.find('.fas');

    if (row.child.isShown()) {
      // This row is already open - close it
      row.child.hide();
      $tr.removeClass('shown');
      $button.removeClass('show');
      $icon.addClass('fa-chevron-right').removeClass('fa-chevron-down');
    } else {
      // Open this row
      const rowChild = row.child($nestedTable.html());
      rowChild.show();
      $tr.addClass('shown');
      $button.addClass('show');
      $icon.removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
  }

  $('tr.parent-row').each((index, row) => {
    const $tr = $(row);
    $tr.find('.toggle-nested-table-button').on('click', toggleNestedTable);
  });
}

$(document).ready(() => {
  setupNestedRowsTable($('table.two-level-table'));
});

export default setupNestedRowsTable;
