/* eslint-disable no-underscore-dangle */
import React, {
  useEffect,
  useState,
  useCallback
} from 'react';
import DataTable from 'react-data-table-component';
import {
  arrayOf, shape, string, number, oneOf, oneOfType, bool, func
} from 'prop-types';
import DataTablePaginator from './DataTablePaginator';
import PopoverIcon from '../../PopoverIcon/PopoverIconComponent';
import ExpandableSubtable from './ExpandableSubtable';

const LANGUAGES = {
  long_name: 'long_name',
  short_name: 'short_name',
  english_name: 'english_name'
};

export default function GenericTable({
  language,
  tableData,
  recordsPerPages,
  currentPage,
  handleChangeCurrentPage,
  isLoadingTable,
  handleChangeSorting,
  sorting,
  expandableRows
}) {
  const [columns, setColumns] = useState([]);

  const handleSorting = useCallback((e, sortProperty) => {
    const { classList } = e.target;
    let sort = '';

    if (classList.contains('sorting_asc')) {
      e.target.classList.replace('sorting_asc', 'sorting_desc');
      sort = 'desc';
    } else if (classList.contains('sorting_desc')) {
      e.target.classList.replace('sorting_desc', 'sorting_asc');
      sort = 'asc';
    } else {
      e.target.classList.add('sorting_desc');
      sort = 'desc';
    }

    const buttonsSorting = document.querySelectorAll("[data-react-sorting='sorting']");
    buttonsSorting.forEach((button) => {
      if (button.id !== e.target.id) {
        button.classList.remove('sorting_desc');
        button.classList.remove('sorting_asc');
      }
    });

    handleChangeSorting({
      order: sort,
      property: sortProperty
    });
  }, [handleChangeSorting]);

  const renderNameCol = useCallback((name, observations, isSortable, id, sortProperty, width) => {
    if (isSortable) {
      return (
        <div className="d-flex align-items-start" style={{ width: `${width}ch` }}>
          <button
            id={id}
            onClick={(e) => handleSorting(e, sortProperty)}
            type="button"
            data-react-sorting="sorting"
            className={`sorting ${sorting && sortProperty === sorting.property && `sorting_${sorting.order}`} pl-0 text-left`}
          >
            {name}
          </button>
          <PopoverIcon icon="fa-info-circle" popoverText={observations} popoverTitle={gettext('column observations')} />
        </div>
      );
    }

    return (
      <div className="d-flex align-items-start" style={{ width: `${width}ch` }}>
        <div>{name}</div>
        <PopoverIcon icon="fa-info-circle" popoverText={observations} popoverTitle={gettext('column observations')} />
      </div>
    );
  }, [handleSorting, sorting]);

  const buildColums = useCallback((data) => {
    const maxColumnsCharsArray = data.viewToVisualize.columns.map(({ shortName }) => {
      const charactersLength = tableData.results
        .reduce((acc, row) => {
          const currentCharsAmount = row[shortName].value?.length;

          return currentCharsAmount > acc ? currentCharsAmount : acc;
        }, 0);

      return { shortName, charactersLength };
    });

    const newColumns = data.viewToVisualize.columns.map((column, index) => {
      const { charactersLength } = maxColumnsCharsArray
        .find(({ shortName }) => shortName === column.shortName) ?? {};

      const dataColumnsAmount = data.viewToVisualize.columns.length;
      const totalColumnsAmount = dataColumnsAmount + 1; // Total view columns + action column

      const getWidthByCharacters = () => {
        const MIN_CHARS_LENGTH = 20;

        // Aproximate width by characters (100 characters)
        const MAX_CHARS_LENGTH = 90;

        const minimumColumnWidth = charactersLength < MIN_CHARS_LENGTH
          ? MIN_CHARS_LENGTH
          : charactersLength;

        const maximumColumnWidth = minimumColumnWidth >= MAX_CHARS_LENGTH
          ? MAX_CHARS_LENGTH
          : minimumColumnWidth;

        return `${maximumColumnWidth} * 0.55rem`;
      };

      const getWidthByPercentage = () => {
        const columnWidthByPercentage = 100 / totalColumnsAmount;

        return `${columnWidthByPercentage}%`;
      };

      const isDynamicColumnWidth = dataColumnsAmount >= 4;

      // Final column width is given by the amount of columns selecteds in the view
      // Width will be calculated by characters length if the amount of columns is greater than 4
      // Otherwise, width will be calculated by percentage
      // (in order to keep table consistent with small amount of columns)
      const columnWidth = isDynamicColumnWidth ? getWidthByCharacters() : getWidthByPercentage();
      const sidesPadding = '2rem';

      const newColumn = {
        width: `calc(${columnWidth} + ${sidesPadding})`,
        selector: (row) => {
          if (typeof row[column.shortName] !== 'object') {
            return <p>{row[column.shortName]}</p>;
          }
          if (row[column.shortName].url) {
            return <a href={row[column.shortName].url}>{row[column.shortName].value}</a>;
          }
          if (row[column.shortName].type === 'rich_string') {
            // The HTML of the cell is inserted directly into the div,
            // it is very important that the HTML is previously sanitized
            // eslint-disable-next-line react/no-danger
            return <div dangerouslySetInnerHTML={{ __html: row[column.shortName].value }} />;
          }
          return <p>{row[column.shortName].value}</p>;
        }
      };

      if (language === LANGUAGES.english_name) {
        if (!column.englishName) {
          newColumn.name = renderNameCol(
            gettext('language not available'),
            column.observations, column.isSortable, index, column.shortName, columnWidth);
        } else {
          newColumn.name = renderNameCol(
            column.englishName,
            column.observations, column.isSortable, index, column.shortName, columnWidth);
        }
      } else if (language === LANGUAGES.long_name) {
        newColumn.name = renderNameCol(
          column.longName,
          column.observations, column.isSortable, index, column.shortName, columnWidth);
      } else {
        newColumn.name = renderNameCol(
          column.shortName,
          column.observations, column.isSortable, index, column.shortName, columnWidth);
      }

      return newColumn;
    });

    newColumns.push({
      name: <div className="w-100 text-center">{gettext('actions')}</div>,
      cell: (row) => (
        <div className="w-100 d-flex justify-content-center align-self-start">
          <a className="btn btn-outline-secondary" href={row._meta.update_url}>
            <i className="fa fa-edit" />
          </a>

          <a className="btn btn-outline-secondary ml-1" href={row._meta.detail_url}>
            <i className="fa fa-eye" />
          </a>
        </div>
      )
    });

    return newColumns;
  }, [language, renderNameCol, tableData.results]);

  useEffect(() => {
    if (tableData?.results) {
      setColumns(buildColums(tableData));
    }
  }, [buildColums, tableData]);

  const disableExpandableRow = (row) => !row?._hidden_table?.rows.length;

  const loading = (
    <div className="py-5 d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  const tableHeight = `calc(100vh - ${expandableRows ? '30rem' : '24rem'})`;

  return (
    <div>
      <DataTable
        data={tableData.results}
        columns={columns}
        fixedHeader
        progressPending={isLoadingTable}
        progressComponent={loading}
        expandableRowDisabled={disableExpandableRow}
        expandableRows={expandableRows}
        expandableRowsComponent={(table) => (
          <ExpandableSubtable
            subTable={table?.data?._hidden_table}
            language={language}
          />
        )}
        fixedHeaderScrollHeight={tableHeight}
      />
      { tableData?.results?.length > 0 && (
      <DataTablePaginator
        currentPage={currentPage}
        itemsPerPage={recordsPerPages}
        totalCount={tableData.count}
        handleChangeCurrentPage={handleChangeCurrentPage}
      />
      )}
    </div>
  );
}

GenericTable.propTypes = {
  language: oneOf([
    LANGUAGES.english_name,
    LANGUAGES.long_name,
    LANGUAGES.short_name
  ]).isRequired,
  tableData: shape({
    viewToVisualize: shape({
      id: number.isRequired,
      columns: arrayOf(shape({
        shortName: string.isRequired,
        longName: string,
        englishName: string
      }))
    }),
    count: number,
    next: string,
    previous: string,
    results: arrayOf(
      oneOfType([string, shape({
        type: string,
        url: string,
        value: string,
        isSortable: bool
      })])
    )
  }).isRequired,
  recordsPerPages: number.isRequired,
  currentPage: number.isRequired,
  handleChangeCurrentPage: func.isRequired,
  handleChangeSorting: func.isRequired,
  isLoadingTable: bool.isRequired,
  sorting: shape({
    order: string,
    property: string
  }),
  expandableRows: bool
};

GenericTable.defaultProps = {
  sorting: undefined,
  expandableRows: false
};
