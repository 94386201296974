$(document).ready(() => {
  const $tables = $('table.active-table');
  $tables.each((_, table) => {
    const order = table.getAttribute('order');
    const datatable = $(table).DataTable({
      ...(order && {
        // eslint-disable-next-line no-eval
        order: eval(order) ?? []
      }),
      lengthMenu: [[-1, 10, 25, 50], ['Todos', 10, 25, 50]],
      language: {
        sProcessing: 'procesando...',
        sLengthMenu: 'mostrar _MENU_ registros',
        sZeroRecords: 'no se encontraron resultados',
        sEmptyTable: 'ningún dato disponible en esta tabla',
        sInfo: 'mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sSearch: 'buscar:',
        sInfoThousands: ',',
        sLoadingRecords: 'cargando...',
        oPaginate: {
          sFirst: 'primero',
          sLast: 'último',
          sNext: 'siguiente',
          sPrevious: 'anterior'
        },
        oAria: {
          sSortAscending: ': activar para ordenar la columna de manera ascendente',
          sSortDescending: ': activar para ordenar la columna de manera descendente'
        },
        buttons: {
          copy: 'copiar',
          colvis: 'visibilidad'
        }
      }
    });

    datatable.columns.adjust().draw();
  });
});
